import { message } from 'antd';
import { notesApi } from 'app/services/notes';
import qs from 'qs';
import { useCallback } from 'react';

const useNote = (props: UseNoteProps): UseNotePayload => {
  const {
    data: commentsQuery,
    refetch,
    isLoading,
    isFetching,
    isUninitialized
  } = notesApi.useFindNotesQuery(
    {
      requestQuery:
        props.allFamilyQuery ??
        qs.stringify(
          {
            filters: props?.role
              ? {
                  type: props?.type,
                  ...(props?.role === 'student'
                    ? { student: props?.targetEntityId, parent: { $null: true } }
                    : props?.role === 'parent'
                    ? { parent: props?.targetEntityId }
                    : {})
                }
              : {
                  type: props?.type,
                  ...(props?.source === 'student' || props?.source === 'sales'
                    ? { student: props?.targetEntityId }
                    : props?.source === 'parent' || props?.source === 'parent_payment'
                    ? { parent: props?.targetEntityId }
                    : props?.source === 'teacher'
                    ? { teacher: props?.targetEntityId }
                    : props?.source === 'register' && props?.teacherId
                    ? {
                        student: props?.targetEntityId,
                        teacher: props?.teacherId
                      }
                    : props?.source === 'register'
                    ? {
                        student: props?.targetEntityId
                      }
                    : {})
                },
            populate: '*',
            sort: ['createdAt:desc'],
            pagination: {
              pageSize: 1000
            }
          },
          {
            encodeValuesOnly: true
          }
        )
    },
    { skip: !props?.targetEntityId, refetchOnMountOrArgChange: true }
  );

  const [createOneNote, { isLoading: isCreatingNote }] = notesApi.useCreateOneNoteMutation();
  const [deleteOneNote, { isLoading: isDeletingNote }] = notesApi.useDeleteOneNoteMutation();
  const [updateOneNote, { isLoading: isUpdatingNote }] = notesApi.useUpdateOneNoteMutation();

  const addNote = useCallback(
    async (
      content: string,
      reason: string,
      response: string,
      role?: string,
      targetId?: string,
      postponementDetails?: any,
      child?: number
    ) => {
      try {
        const noteContent = postponementDetails
          ? `Postponed on: ${postponementDetails.postponedOn}\nPostponement Date: ${postponementDetails.postponementDate} (UTC)\nPostponement Note: ${postponementDetails.postponeReason}`
          : content;
        await createOneNote({
          text: noteContent,
          admin: props.sourceEntityId,
          ...(role
            ? role == 'student'
              ? { student: targetId }
              : role == 'parent'
              ? { parent: targetId, student: child }
              : {}
            : props.role
            ? props.role == 'student'
              ? { student: props?.targetEntityId }
              : props.role == 'parent'
              ? { parent: props?.targetEntityId, student: child }
              : {}
            : props?.source === 'student' || props?.source === 'sales'
            ? { student: props?.targetEntityId }
            : props?.source === 'parent' || props?.source === 'parent_payment'
            ? { parent: props?.targetEntityId }
            : props?.source === 'teacher'
            ? { teacher: props?.targetEntityId }
            : props?.source === 'register'
            ? {
                student: props?.targetEntityId,
                teacher: props?.teacherId,
                register: props?.regId,
                class: props?.classId
              }
            : {}),
          source:
            props?.source === 'student'
              ? 'student_detail'
              : props?.source === 'parent'
              ? 'parent_detail'
              : props?.source === 'teacher'
              ? 'teacher_detail'
              : props?.source,
          type: props?.type,
          reason,
          response
        }).unwrap();
        await refetch();
      } catch (e) {
        message.error('Something wrong happened');
      }
    },
    [createOneNote, props, refetch]
  );

  const deleteNote = useCallback(
    async (id: string) => {
      await deleteOneNote({ id }).unwrap();
      if (!isUninitialized) await refetch();
    },
    [deleteOneNote, refetch]
  );

  const updateNote = useCallback(
    async (
      id: string,
      content: string,
      response: string,
      reason: string,
      student: string,
      parent: string,
      timestamp: string
    ) => {
      await updateOneNote({
        id: id!,
        text: content,
        reason: reason,
        response: response,
        student: student,
        parent: parent,
        timestamp: timestamp
      }).unwrap();
      await refetch();
    },
    [updateOneNote, refetch]
  );

  return {
    addNote,
    deleteNote,
    updateNote,
    refetch,
    isLoading: isLoading || isCreatingNote || isUpdatingNote || isDeletingNote || isFetching,
    notes:
      commentsQuery?.data?.map((data: any) => {
        return {
          id: data?.id?.toString()!,
          content: data?.attributes?.text!,
          datetime: data?.attributes?.createdAt!,
          name: data?.attributes?.admin?.data?.attributes?.name || data?.attributes?.teacher?.data?.attributes?.name,
          source: data?.attributes?.source,
          response: data?.attributes?.response,
          reason: data?.attributes?.reason,
          admin: data?.attributes?.admin,
          student: data?.attributes?.student,
          parent: data?.attributes?.parent,
          type: data?.attributes?.type
        };
      }) || []
  };
};

export default useNote;

export type UseNoteProps = {
  sourceEntityId?: string;
  targetEntityId?: string;
  classId?: string;
  regId?: string;
  teacherId?: string;
  source?: string;
  allFamilyQuery?: any;
  role?: string;
  type?: string;
};

export type UseNotePayload = {
  refetch: Function;
  notes: Note[];
  addNote: Function;
  deleteNote: Function;
  updateNote: Function;
  isLoading: boolean;
};

export type Note = {
  id: string;
  content: string;
  datetime: string;
  name: string;
  source: string;
  response: string;
  reason: string;
  admin: any;
  student: any;
  parent: any;
  type: string;
};
