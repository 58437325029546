import { Modal } from 'antd';
import { ReactComponent as CallIcon } from 'app/assets/svgs/phone-icon.svg';
import { CallLogsCard } from 'app/components/Cards/CallLogsCard';
import { formatContacts, handleGroupChange } from 'app/components/Cards/CallLogsCard/handleCallLogsChanges';
import { FamilyTab } from 'app/components/NoteComponent/FamilyTab';
import useNote from 'app/components/NoteComponent/useNote';
import Spinner from 'app/components/Spinner';
import { Errors, FormControl, TextArea } from 'app/components/v1/Input';
import { Select } from 'app/components/v1/Select';
import { TimePicker } from 'app/components/v1/Time-Picker';
import familyLinkApi from 'app/services/family-link';
import { studentApi } from 'app/services/student';
import { Student } from 'app/services/types';
import { MAIN_SELECTORS } from 'app/store';
import { attendanceResponse, callReasons, generalIssueResponse, salesCallResponse } from 'app/utils/consts';
import { yup } from 'app/utils/validations';
import { useFormik } from 'formik';
import moment from 'moment';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PhoneNumber = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  div {
    width: fit-content !important;
  }
  svg {
    margin-right: 6px;
  }
  path {
    stroke: #2096f3;
  }
  .phone-link {
    color: #000000 !important;
    font-weight: 600;
    font-size: 14px;
  }
`;

const LogsHistory = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  span {
    font-size: 20px;
    font-weight: 600;
  }

  .all-logs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 21rem;
    overflow: scroll;
    padding-top: 10px;
  }
`;

interface ContactParentModalProps {
  show: boolean;
  setShowCallModal: (x: boolean) => void;
  studentId?: number;
  primaryParentId?: number;
  relId?: number;
  registerId?: number;
  student?: Student;
  noteRequired?: boolean;
  activeBackground: boolean;
  setTargetId: any;
  targetId: any;
  setRole: (x?: string) => void;
  role: string;
  parentId?: number;
  groups: any;
}

export const ContactParentModal = (props: ContactParentModalProps) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [formattedContacts, setformattedContacts] = useState<any[]>([]);
  const [defaultParent, setDefaultParent] = useState<number | undefined>(undefined);
  const user = useSelector(MAIN_SELECTORS.user);

  const [wholeNumber1, setwholeNumber1] = useState<string | undefined>(undefined);
  const [wholeNumber2, setwholeNumber2] = useState<string | undefined>(undefined);
  const [disableBackground, setDisabledBackground] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState<string>();
  const [disableGroupBtn, setDisableGroupBtn] = useState<boolean>(false);

  const form = useFormik<{
    phone?: number | undefined;
    reason: string;
    response: string | undefined;
    note: string | undefined;
    noteRequired: boolean;
    timestamp?: string;
  }>({
    initialValues: {
      phone: defaultParent,
      reason: props.relId && props.registerId ? 'attendance' : 'sales',
      response: undefined,
      note: '',
      noteRequired: props?.noteRequired ?? false
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      phone: yup.number().required('Choose a contact'),
      response: yup.string().required('you have to choose a call response'),
      noteRequired: yup.boolean(),
      note: yup.string().when('noteRequired', (noteRequired, schema) => {
        if (noteRequired?.[0]) return schema.required('you must add a note');
        return schema;
      })
    }),
    onSubmit: async (values) => {
      const targetContact: any = formattedContacts.filter((contact) => values.phone == contact.value)?.[0];
      props.setTargetId(targetContact.contactId);
      props.setRole(targetContact?.isParent ? 'parent' : 'student');

      if (editMode == false) {
        await addNote(
          values.note,
          values.reason,
          values.response,
          targetContact?.isParent ? 'parent' : 'student',
          targetContact.contactId,
          undefined,
          targetContact?.isParent && props.studentId ? props.studentId : undefined
        );
      } else {
        if (targetContact?.isParent) {
          await updateNote(
            editingNoteId,
            values.note,
            values.response,
            values.reason,
            targetContact?.isParent && props.studentId ? props.studentId : null,
            targetContact.contactId,
            values.timestamp
          );
        } else {
          await updateNote(
            editingNoteId,
            values.note,
            values.response,
            values.reason,
            targetContact.contactId,
            null,
            values.timestamp
          );
        }
      }

      props.setShowCallModal(false);
      form.resetForm();
      setEditMode(false);
    }
  });

  const [updateStudent] = studentApi.useUpdateStudentMutation();
  const { data: familyLinks } = familyLinkApi.endpoints.getFamilyLinks.useQuery(
    {
      filters: {
        [props.studentId ? 'students' : 'parents']: {
          id: {
            $eq: props.studentId ?? props.parentId
          }
        }
      },
      populate: {
        parents: {
          fields: ['id', 'name', 'phone', 'phone2', 'phoneCountryCode', 'phoneCountryCode2', 'lastName']
        },
        students: {
          fields: ['id', 'name', 'phone', 'phone2', 'phoneCountryCode', 'phoneCountryCode2', 'lastName']
        }
      }
    },
    { refetchOnMountOrArgChange: true, skip: !props.studentId && !props.parentId }
  );

  const [responseOptions, setResponseOptions] = useState(
    form.values.reason == 'attendance'
      ? attendanceResponse
      : form.values.reason == 'general'
      ? generalIssueResponse
      : salesCallResponse
  );

  const {
    isLoading: isNotesLoading,
    notes: logs,
    addNote,
    updateNote
  } = useNote({
    type: 'call',
    role: props.role,
    sourceEntityId: user?.entity?.id!,
    targetEntityId: props.targetId,
    source: 'sales',
    allFamilyQuery:
      props.targetId == 'all'
        ? qs.stringify({
            filters: {
              $or: [
                { parent: familyLinks?.data?.[0]?.attributes?.parents?.data?.map((parent: any) => parent.id) },
                { student: familyLinks?.data?.[0]?.attributes?.students?.data?.map((student: any) => student.id) }
              ],
              type: 'call'
            },
            populate: '*',
            sort: ['createdAt:desc'],
            pagination: {
              pageSize: 1000
            }
          })
        : undefined
  });

  useEffect(() => {
    if (!editMode) {
      form.resetForm();
    }
  }, [editMode]);

  useEffect(() => {
    if (form.values.reason == 'attendance') {
      setResponseOptions(attendanceResponse);
    } else if (form.values.reason == 'general') {
      setResponseOptions(generalIssueResponse);
    } else {
      setResponseOptions(salesCallResponse);
    }
  }, [form.values.reason]);

  useEffect(() => {
    setformattedContacts(
      formatContacts(familyLinks?.data, setDefaultParent, form, props.primaryParentId, props.student, props.studentId)
    );
  }, [familyLinks, props.student]);

  useEffect(() => {
    const parentInfo = formattedContacts.filter((contact) =>
      form.values.phone ? form.values.phone == contact.value : defaultParent == contact.value
    )[0];
    if (parentInfo?.code && parentInfo?.phone) {
      setwholeNumber1(`${parentInfo?.code}${parentInfo?.phone}`);
    } else {
      setwholeNumber1(undefined);
    }

    if (parentInfo?.code2 && parentInfo?.phone2) {
      setwholeNumber2(`${parentInfo?.code2}${parentInfo?.phone2}`);
    } else {
      setwholeNumber2(undefined);
    }
  }, [form.values.phone, formattedContacts]);

  useEffect(() => {
    form.setFieldValue('noteRequired', props?.noteRequired);
  }, [props?.noteRequired]);

  const formattedTime = useMemo(() => {
    if (!form.values.timestamp) return undefined;
    const date = new Date(form.values.timestamp);
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }, [form.values.timestamp]);

  return (
    <Modal
      rootClassName={`${props?.activeBackground ? 'pe-none' : ''}`}
      className="w-50 mx-auto new-call-sales"
      open={props.show}
      onCancel={(e) => {
        if (e.currentTarget.id === 'discardButton' && editMode) {
          setEditMode(false);
        } else {
          props.setShowCallModal(false);
          setEditMode(false);
          form.resetForm();
        }
      }}
      onOk={() => form.submitForm()}
      okText={editMode ? 'Update' : 'Submit'}
      confirmLoading={isNotesLoading}
      okButtonProps={{ disabled: isNotesLoading || disableGroupBtn }}
      cancelButtonProps={{ id: 'discardButton' }}
      zIndex={1030}
      mask={props.activeBackground ? false : true}
      maskClosable={props.activeBackground ? false : true}
      modalRender={(modal) =>
        props.activeBackground ? (
          <Draggable disabled={disableBackground} axis="both">
            <div onClick={(e) => e.stopPropagation()} style={{ position: 'absolute', width: '100%' }}>
              {modal}
            </div>
          </Draggable>
        ) : (
          modal
        )
      }
    >
      <h3
        onMouseOver={() => setDisabledBackground(false)}
        onMouseOut={() => setDisabledBackground(true)}
        onClick={(e) => e.stopPropagation()}
        className="header"
      >
        {editMode ? 'Edit' : 'New'} Call
      </h3>
      <div
        onClick={(e) => e.stopPropagation()}
        onMouseOver={() => setDisabledBackground(true)}
        className="d-flex flex-row gap-10 w-100"
      >
        <div className="d-flex flex-column gap-2 w-50">
          <FormControl>
            <Select
              className="d-flex flex-row justify-content-center "
              name={'Contact'}
              options={formattedContacts}
              value={form.values.phone || defaultParent}
              onChange={(e) => {
                form.setFieldValue('phone', e);
              }}
              allowClear={false}
            />
            {form.errors.phone && <Errors errors={{ required: [form.errors.phone] }} />}
          </FormControl>
          {wholeNumber1 ? (
            <PhoneNumber>
              <a className="phone-link" href={`tel:${wholeNumber1}`}>
                <CallIcon />
                {wholeNumber1}
              </a>
            </PhoneNumber>
          ) : (
            <></>
          )}
          {wholeNumber2 ? (
            <PhoneNumber>
              <a className="phone-link" href={`tel:${wholeNumber2}`}>
                <CallIcon />
                {wholeNumber2}
              </a>
            </PhoneNumber>
          ) : (
            <></>
          )}
          <FormControl>
            <Select
              allowClear={false}
              className="d-flex flex-row justify-content-center  mb-2"
              name={'Call Reason'}
              options={callReasons}
              value={form.values.reason}
              onChange={(value) => form.setFieldValue('reason', value)}
              disabled={editMode && form.values.reason == 'sales'}
            />
          </FormControl>
          <FormControl>
            <Select
              allowClear={false}
              className="d-flex flex-row justify-content-center "
              name={'Call Response'}
              options={responseOptions}
              value={form.values.response}
              onChange={(value) => form.setFieldValue('response', value)}
            />
            {form.errors.response && <Errors errors={{ required: [form.errors.response] }} />}
          </FormControl>
          <FormControl className="h-100">
            <TextArea
              name="Notes"
              rows={5}
              style={{ resize: 'none', height: '100%' }}
              value={form.values.note}
              onChange={(value) => {
                form.setFieldValue('note', value);
              }}
            />
            {form.errors.note && <Errors errors={{ required: [form.errors.note] }} />}
          </FormControl>
          {editMode ? (
            <TimePicker
              allowClear={false}
              name="Change call time"
              value={formattedTime}
              onChange={(e) => {
                const utcTime = moment(e, 'HH:mm').utc().format('HH:mm');
                const date = new Date(form.values.timestamp!);
                const [hours, minutes] = utcTime.split(':').map(Number);
                date.setUTCHours(hours, minutes, 0, 0);
                form.setFieldValue('timestamp', date.toISOString());
              }}
            />
          ) : (
            <></>
          )}
          {props?.studentId ? (
            <FormControl>
              <Select
                className="d-flex flex-row justify-content-center "
                name={`${props?.student?.name ? `${props?.student?.name}'s ` : ''} Group`}
                options={props?.groups}
                value={props?.student?.group?.data?.id?.toString()}
                onChange={async (value) =>
                  await handleGroupChange(
                    props?.studentId!,
                    value,
                    setDisableGroupBtn,
                    updateStudent,
                    props.setShowCallModal
                  )
                }
                disabled={disableGroupBtn}
              />
            </FormControl>
          ) : (
            <></>
          )}
        </div>
        <LogsHistory>
          <span>Logs</span>
          <FamilyTab
            linkedParents={familyLinks?.data?.[0]?.attributes?.parents?.data}
            linkedStudents={familyLinks?.data?.[0]?.attributes?.students?.data}
            setTargetId={props.setTargetId}
            targetId={props.targetId}
            setRole={props.setRole}
            role={props?.role}
            showSpinner={showSpinner}
            setShowSpinner={setShowSpinner}
          />
          <div className="all-logs">
            {isNotesLoading ? (
              <Spinner />
            ) : (
              logs?.map((log, index) => {
                return (
                  <CallLogsCard
                    key={index}
                    index={index}
                    response={log.response}
                    parent={log?.parent?.data?.attributes}
                    student={log?.student?.data?.attributes}
                    createdAt={log?.datetime}
                    admin={log?.admin?.data?.attributes}
                    reason={log?.reason}
                    content={log?.content}
                    editable={true}
                    id={log?.id}
                    source={log.source}
                    setEditMode={setEditMode}
                    onEdit={() => {
                      setEditingNoteId(log?.id);
                      form.setFieldValue('reason', log?.reason);
                      form.setFieldValue('response', log?.response);
                      form.setFieldValue('note', log?.content);
                      form.setFieldValue('timestamp', log?.datetime);
                      if (log.parent?.data) {
                        form.setFieldValue(
                          'phone',
                          formattedContacts.filter(
                            (contact) => contact?.isParent == true && contact?.contactId == log.parent?.data?.id
                          )?.[0]?.value
                        );
                      } else if (log.student?.data) {
                        form.setFieldValue(
                          'phone',
                          formattedContacts.filter(
                            (contact) => contact?.isParent == false && contact?.contactId == log.student?.data?.id
                          )?.[0]?.value
                        );
                      }
                    }}
                  />
                );
              })
            )}
          </div>
        </LogsHistory>
      </div>
    </Modal>
  );
};
