import { humanize, toTitleCase, transform } from '@alduino/humanizer/string';
import { Popconfirm } from 'antd';
import { ReactComponent as DoubleArrow } from 'app/assets/svgs/double-arrow-icon.svg';
import { ReactComponent as CallIcon } from 'app/assets/svgs/phone-icon.svg';
import DateText from 'app/components/DateText/DateText';
import { IconComponents } from 'app/components/Icons';
import useNote from 'app/components/NoteComponent/useNote';
import { Button } from 'app/components/v1/Button';
import { attendanceResponse, callReasons, generalIssueResponse, salesCallResponse } from 'app/utils/consts';
import { useState } from 'react';
import styled from 'styled-components';
import { callLogsCardProps } from './types';

const LogEntry = styled.div`
  margin-bottom: 2px;
  border-radius: 8px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  box-shadow: 0px 0px 4px 0px #00000014;

  .icons {
    margin-left: auto;
    display: flex;
    flex-direction: row;
  }

  .light-text {
    color: #92929d;
    font-weight: 500;
    font-size: 12px;
  }

  .normal-text {
    font-weight: 400;
    font-size: 14px;
  }

  .first-row {
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    gap: 9px;

    .edit-btn {
      background: transparent;
      padding: 0px;n
      margin: 0px;
      height: fit-content;
      margin-left: 15px;
      path {
        stroke: #4c54a0 !important;
      }
    }
    .delete-btn {
      background: transparent;
      padding: 0px;
      height: fit-content;
      margin: 0px;

      path {
        stroke: #4c54a0 !important;
      }
    }

    &.noAnswer {
      path {
        stroke: red;
      }
    }

    svg {
      width: 15px;
      height: 15px;
    }

    path {
      stroke: #3dd598;
    }

    span {
      font-size: 12px;
    }
  }
  .second-row {
    display: flex;
    flex-direction: column;
  }
  .third-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    div {
      text-wrap: nowrap;

      &.response {
        color: #3dd598;
      }

      &.noAnswer {
        color: #ffc542;
      }
    }
  }
  .fourth-row {
    display: flex;
    flex-direction: column;
    .notes-title {
      color: #4c54a0;
      font-weight: 600;
    }
    .notes-button {
      color: #4c54a0;
      background: transparent;
      border: none;
      font-size: 12px;
    }
    .notes-body {
      line-height: 12px;
    }
  }
`;

export const CallLogsCard = (props: callLogsCardProps) => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  const { deleteNote } = useNote({});

  const toggleExpanded = (index: number) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  return (
    <LogEntry key={props.index}>
      <div className={`first-row ${props.response}`}>
        <CallIcon />
        <span className="light-text">Called {props?.student && props?.parent ? 'parent' : ''} : </span>
        {props?.parent ? (
          <span>
            {props?.parent?.name} {props?.parent?.lastName}
          </span>
        ) : (
          <span>
            {props?.student?.name} {props?.student?.lastName}
          </span>
        )}

        <span className="light-text">
          {' '}
          <DateText className="light-text" date={props?.createdAt} format={'fullDate'} />
        </span>
        {props?.editable ? (
          <div className="icons">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={async () => {
                await deleteNote(props.id);
              }}
            >
              <Button className="delete-btn" buttonType="text">
                <IconComponents.DeleteIconComponent />
              </Button>
            </Popconfirm>
            <Button
              className="edit-btn"
              buttonType="text"
              onClick={() => {
                if (props?.onEdit && props.setEditMode) {
                  props?.onEdit();
                  props.setEditMode(true);
                }
              }}
            >
              <IconComponents.EditIconComponent />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="second-row">
        <span className="normal-text">{props?.admin?.name}</span>
        <span className="light-text">{transform(humanize(String(props?.source)), toTitleCase)}</span>
      </div>
      <div className="third-row">
        <div>{callReasons?.filter((res) => res.value == props?.reason)?.[0]?.label}</div>
        <DoubleArrow />
        <div className={`response ${props.response}`}>
          {props.reason == 'attendance'
            ? `"${attendanceResponse.filter((resp) => resp.value == props.response)?.[0]?.label}"`
            : props.reason == 'general'
            ? `"${generalIssueResponse.filter((resp) => resp.value == props.response)?.[0]?.label}"`
            : `"${salesCallResponse.filter((resp) => resp.value == props.response)?.[0]?.label}"`}
        </div>
      </div>
      {props.content && props.content.length > 0 ? (
        <div className="fourth-row">
          <span className="light-text notes-title">Notes</span>
          <span className="notes-body">
            <span className="light-text">
              {expandedIndexes.includes(props.index)
                ? props.content
                : props.content.length > 50
                ? `${props.content.slice(0, 50)}...`
                : props.content}
            </span>
            {props.content.length > 50 ? (
              <button className="notes-button" onClick={() => toggleExpanded(props.index)}>
                View {expandedIndexes.includes(props.index) ? 'Less' : 'More'}
              </button>
            ) : (
              <></>
            )}
          </span>
        </div>
      ) : (
        <></>
      )}
    </LogEntry>
  );
};
